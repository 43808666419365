import React from 'react'
import SbEditable from 'storyblok-react'
import makeStyles from '@material-ui/styles/makeStyles'
import { Box } from '@material-ui/core'
import renderBlok from '@renderBlok'

const useStyles = makeStyles((theme) => ({
  checklistRoot: {},
}))

const Checklist = (props) => {
  const { checklistItems, icon, iconSize, fontSize } = props.blok
  const classes = useStyles(props)
  const minFontSize = Math.max(fontSize, 16)

  return (
    <SbEditable content={props.blok}>
      <Box className={classes.checklistRoot}>
        {!!checklistItems &&
          checklistItems.map((item) => (
            <Box key={item._uid} className={classes.item}>
              {renderBlok(item, {
                icon: icon,
                iconSize: iconSize,
                fontSize: minFontSize,
                isInChecklist: true,
              })}
            </Box>
          ))}
      </Box>
    </SbEditable>
  )
}

export default Checklist
